body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
#root {
  height: 100%;
}

.ant-table-tbody tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.ant-table-tbody tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
.tasks, .tasks li{
  list-style: none;
}
.personcenter .ant-form-item{
  margin-bottom: 0;
}
.personcenter .ant-form-item-label{
  font-weight: bold;
}

/* 文本省略 */
.textellipsis{
  display: block;
  width: 74px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.msgwrapper{
  width: 240px;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #333;
  padding: 15px;
  box-shadow:0 0 24px 2px #ccc;
}
.casetabRightMenu {
  width: 100%;
  position: relative !important;
}
.casetabRightMenu .ant-tabs-nav {
  position: fixed !important;
  background-color: #fff;
  /* max-width: 100%; */
  width: 100%;
  min-width: 355px;
  max-width: 370px;
  z-index: 1000;
  margin-top: -10px !important;
}
@media screen and ( max-width: 1400px ) {
  .casetabRightMenu .ant-tabs-nav {
    width: 355px;
  }
}
.casetabRightMenu .ant-tabs-content-holder {
  /* position: absolute !important; */
  /* margin-top: 62px; */
  padding-top: 60px;
}
.casetabRightMenu .ant-layout-header {
  padding: 0;
}
/* .tabclass {
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.tabclass .chi {
  position: fixed !important;
  background-color: #fff;
  width: calc(100%);
  z-index: 1000;
} */
.sampleProTable .ant-table-expanded-row-fixed {
  width: 100%;
  min-width: 1200px !important;
}

#root .ant-layout {
  min-height: 100px !important;
}
.dm .ant-layout-header {
  height: auto;
  line-height: 100%;
}
.dm .ant-table-title {
  padding: 0 0 10px;
}

.modal-scroll {
  width: 100%;
  height: 260px;
  overflow: auto;
}
.modal-scroll .ant-input-number-handler-wrap {
  display: none;
}