.sidebar {
  height: 100%;
  overflow: auto;
  background: #001529;
}

.sidebar :global(.ant-menu.ant-menu-dark) {
  background: #001529;
}

.sidebar :global(.ant-menu-dark .ant-menu-item > span > a) {
  color: white;
}

.sidebar :global(.ant-menu-root),
.sidebar :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
  background: #001529;
  -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 45%) inset;
  box-shadow: inset 0 2px 8px rgb(0 0 0 / 45%);
}

.sidebar
  :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected) {
  background-color: #5b81a5;
}
